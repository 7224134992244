<template>
    <!-- Copyright Start -->
    <div class="container-fluid copyright py-0">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <img src="../../../../../src/assets/img/beruangmadu.png" height="50">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="text-white mb-4 pmisputih">BERUANG MADU BALIKPAPAN - BERINVESTASI UNTUK PELUANG MODAL
                            USAHA KOTA BALIKPAPAN</h4>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-2">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            {{ JumlahPengunjung }}
                        </h4>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            {{ Hariini }}: {{ G_numFormat(harini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            {{ Bulanini }}: {{ G_numFormat(bulanini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            {{ Tahunini }} ini: {{ G_numFormat(tahunini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            Total: {{ G_numFormat(totalpengunjung) }}
                        </a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            {{ Tautan }}
                        </h4>
                        <a href="https://investasi.balikpapan.go.id/home" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> DPMPTSP KOTA BALIKPAPAN</a>
                        <a href="https://spontan.balikpapan.go.id/landing2/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> SPONTAN</a>
                        <a href="https://oss.go.id/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> OSS</a>
                        <a href="https://simbg.pu.go.id/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> SIM-BG</a>
                        <a href="http://imtn.balikpapan.go.id/mobile/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> IMTN</a>
                        <a href="https://regionalinvestment.bkpm.go.id/portal/index-id.html" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> POTENSI INVESTASI REGIONAL</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-4">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            {{ InfoKontak }}
                        </h4>
                        <a href="#" class="text-white"><i class="fas fa-house-user me-2"></i> DPMPTSP KOTA
                            BALIKPAPAN</a>
                        <a href="#" class="text-white"><i class="fa fa-map-marker-alt me-2"></i> Jl. Ruhui Rahayu I RT 8
                            No 9, Kelurahan Sepinggan Baru, Kecamatan Balikpapan Selatan, Kota Balikpapan, Kalimantan
                            Timur 76115</a>
                        <a href="#" style="font-size: 15px;" class="text-white"><i class="fas fa-envelope me-2"></i>
                            dpmpt@balikpapan.go.id | dpmpt.bpp@gmail.com</a>
                        <a href="#" class="text-white"><i class="fas fa-phone me-2"></i> (0542) 8512311</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid copyright py-0">
        <div class="container">
            <div class="row g-6 align-items-center">
                <div class="col-md-4 text-center text-md-start mb-md-0">
                    <span class="text-white">
                        <a href="#" class="merahmaroon">
                            <i class="fas fa-copyright text-light me-2"></i> DPMPT
                        </a>
                        {{ tahun }} All right reserved.
                    </span>
                </div>
                <div class="col-md-12 text-center text-md-start mb-md-0 text-white">
                    Designed By <a href="https://investasi.balikpapan.go.id/" target="_blank"><span
                            class="merahmaroon">DPMPTSP KOTA BALIKPAPAN</span> </a>,
                    Contributed By
                    <a href="https://parastapa.online/" target="_blank"><span class="merahmaroon">Parastapa
                            Technology</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright End -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

export default {
    components: {
        swal
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            datapesan: '',
            harini: 0,
            bulanini: 0,
            tahunini: 0,
            totalpengunjung: 0,
            bahasa: localStorage.getItem("bahasasistem"),
            JumlahPengunjung:'',
            Hariini:'',
            Bulanini:'',
            Tahunini:'',
            Tautan:'',
            InfoKontak:'',
        };
    },
    methods: {
        async ambildata() {
            if ((this.bahasa === null) || (this.bahasa == 'ina')){
                this.JumlahPengunjung='Jumlah Pengunjung';
                this.Hariini='Hari ini';
                this.Bulanini='Bulan ini';
                this.Tahunini='Tahun ini';
                this.Tautan='Tautan';
                this.InfoKontak='Info Kontak';
            }
            else {
                this.JumlahPengunjung='Number of visitors';
                this.Hariini='This day';
                this.Bulanini='This month';
                this.Tahunini='This year';
                this.Tautan='Link';
                this.InfoKontak='Contact Info';
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationVisitor-GetVisitor?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.harini = Response.data.hariini;
                        this.bulanini = Response.data.bulanini;
                        this.tahunini = Response.data.tahunini;
                        this.totalpengunjung = Response.data.total;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>