<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        style="opacity: 0.7;">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">INFRASTRUKTUR</span>
                <span v-else>INFRASTRUCTURE</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Infrastruktur</span>
                    <span v-else>Infrastructure</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="container-fluid feature py-3">
            <div class="cardputih-header p-0 border-bottom-0">
                <div class="container-fluid feature">
                    <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                                href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                                aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Bandara</span>
                                <span v-else>Airport</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-hotel-tab" data-toggle="pill"
                                href="#custom-tabs-four-hotel" role="tab" aria-controls="custom-tabs-four-hotel"
                                aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Hotel</span>
                                <span v-else>Hotels</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-pariwisata-tab" data-toggle="pill"
                                href="#custom-tabs-four-pariwisata" role="tab"
                                aria-controls="custom-tabs-four-pariwisata" aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Pariwisata</span>
                                <span v-else>Tourist</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-pelabuhan-tab" data-toggle="pill"
                                href="#custom-tabs-four-pelabuhan" role="tab" aria-controls="custom-tabs-four-pelabuhan"
                                aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Pelabuhan</span>
                                <span v-else>Harbor</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-pendidikan-tab" data-toggle="pill"
                                href="#custom-tabs-four-pendidikan" role="tab"
                                aria-controls="custom-tabs-four-pendidikan" aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Pendidikan</span>
                                <span v-else>Education</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="custom-tabs-four-rumahsakit-tab" data-toggle="pill"
                                href="#custom-tabs-four-rumahsakit" role="tab"
                                aria-controls="custom-tabs-four-rumahsakit" aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Rumah Sakit</span>
                                <span v-else>Hospital</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                        aria-labelledby="custom-tabs-four-home-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <center>
                                    <DonutsChartBandara type="donut" :options="chartOptionsbandara"
                                        :series="seriesbandara" height="350">
                                    </DonutsChartBandara>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">
                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatabandara"
                                            class="form-control form-control-sm" :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Jenis</th>
                                                    <th style="width: 100px;">Kelas</th>
                                                    <th style="width: 100px;">Luas</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataBandara"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Jenis }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelas }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Luas }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-hotel" role="tabpanel"
                        aria-labelledby="custom-tabs-four-hotel-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <center>
                                    <DonutsChartHotel type="donut" :options="chartOptionshotel" :series="serieshotel"
                                        height="350">
                                    </DonutsChartHotel>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">
                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatahotel" class="form-control form-control-sm"
                                            :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Klasifikasi</th>
                                                    <th style="width: 100px;">Kamar</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataHotel"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Klasifikasi }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kamar }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-pariwisata" role="tabpanel"
                        aria-labelledby="custom-tabs-four-pariwisata-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-6 text-center">
                                <center>
                                    <DonutsChartPariwisata type="donut" :options="chartOptionspariwisata"
                                        :series="seriespariwisata" height="350">
                                    </DonutsChartPariwisata>
                                </center>
                            </div>
                            <div class="col-lg-6 text-center">
                                <center>
                                    <DonutsChartPariwisataPengelola type="donut"
                                        :options="chartOptionspariwisatapengelola" :series="seriespariwisatapengelola"
                                        height="350">
                                    </DonutsChartPariwisataPengelola>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">
                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatapariwisata"
                                            class="form-control form-control-sm" :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Pengelola</th>
                                                    <th style="width: 100px;">Deskripsi</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataPariwisata"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Pengelola }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Deskripsi }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-pelabuhan" role="tabpanel"
                        aria-labelledby="custom-tabs-four-pelabuhan-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <center>
                                    <DonutsChartPelabuhan type="donut" :options="chartOptionspelabuhan"
                                        :series="seriespelabuhan" height="350">
                                    </DonutsChartPelabuhan>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">
                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatapelabuhan"
                                            class="form-control form-control-sm" :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Jenis</th>
                                                    <th style="width: 100px;">Moda</th>
                                                    <th style="width: 100px;">Rute</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataPelabuhan"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Jenis }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Moda }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Rute }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-pendidikan" role="tabpanel"
                        aria-labelledby="custom-tabs-four-pendidikan-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <center>
                                    <DonutsChartPendidikan type="donut" :options="chartOptionspendidikan"
                                        :series="seriespendidikan" height="350">
                                    </DonutsChartPendidikan>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Data:</span>
                                <span v-else>
                                    Amount of data:</span>
                                {{ G_numFormat(jumlahdata) }}
                                <span style="float: right;">
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman ke:</span>
                                    <span v-else>
                                        Page to</span>
                                    <select v-model="halaman" @change="onChangeHalaman($event)">
                                        <option v-for="index in jumlahhalaman" :key="index">
                                            {{ index }}</option>
                                    </select> <span v-if="(bahasa === null) || (bahasa == 'ina')">dari</span>
                                    <span v-else>
                                        from</span> {{ jumlahhalaman }}
                                </span>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">

                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatapendidikan"
                                            class="form-control form-control-sm" :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Jenis</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataPendidikan"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Bentuk }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-rumahsakit" role="tabpanel"
                        aria-labelledby="custom-tabs-four-rumahsakit-tab">
                        <div class="row px-2">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Grafik</span>
                                        <span v-else>Chart</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-6 text-center">
                                <center>
                                    <DonutsChartRumahsakit type="donut" :options="chartOptionsrumahsakit"
                                        :series="seriesrumahsakit" height="350">
                                    </DonutsChartRumahsakit>
                                </center>
                            </div>
                            <div class="col-lg-6 text-center">
                                <center>
                                    <DonutsChartRumahsakitPengelola type="donut"
                                        :options="chartOptionsrumahsakitpengelola" :series="seriesrumahsakitpengelola"
                                        height="350">
                                    </DonutsChartRumahsakitPengelola>
                                </center>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h2 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data</span>
                                        <span v-else>Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInLeft">
                                    <div class="col-sm-12 float-left pb-2 wow fadeInLeft">
                                        <input type="text" v-model="carikatarumahsakit"
                                            class="form-control form-control-sm" :placeholder="katakunci">
                                    </div>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 150px;">Nama</th>
                                                    <th style="width: 150px;">Alamat</th>
                                                    <th style="width: 150px;">Kelurahan</th>
                                                    <th style="width: 150px;">Kecamatan</th>
                                                    <th style="width: 100px;">Jenis</th>
                                                    <th style="width: 100px;">Kelas</th>
                                                    <th style="width: 100px;">Layanan</th>
                                                    <th style="width: 50px;">SDM</th>
                                                    <th style="width: 50px;">Alkes</th>
                                                    <th style="width: 100px;">Latitude</th>
                                                    <th style="width: 100px;">Longitude</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataDataRumahsakit"
                                                :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">{{ urutlist + 1 }}</td>
                                                        <td class="text-justify">
                                                            {{ datalist.nama }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alamat }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelurahan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kecamatan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Jenis }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Kelas }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Layanan }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.SDM }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Alkes }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Latitude }}
                                                        </td>
                                                        <td class="text-justify">
                                                            {{ datalist.Longitude }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, DonutsChartBandara: VueApexCharts, DonutsChartHotel: VueApexCharts
        , DonutsChartPariwisata: VueApexCharts, DonutsChartPariwisataPengelola: VueApexCharts
        , DonutsChartPelabuhan: VueApexCharts, DonutsChartPendidikan: VueApexCharts
        , DonutsChartRumahsakit: VueApexCharts, DonutsChartRumahsakitPengelola: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            databandara: [],
            carikatabandara: '',
            seriesbandara: [],
            chartOptionsbandara: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datahotel: [],
            carikatahotel: '',
            serieshotel: [],
            chartOptionshotel: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datapariwisata: [],
            carikatapariwisata: '',
            seriespariwisata: [],
            chartOptionspariwisata: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            seriespariwisatapengelola: [],
            chartOptionspariwisatapengelola: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datapelabuhan: [],
            carikatapelabuhan: '',
            seriespelabuhan: [],
            chartOptionspelabuhan: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datapendidikan: [],
            carikatapendidikan: '',
            seriespendidikan: [],
            chartOptionspendidikan: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datarumahsakit: [],
            carikatarumahsakit: '',
            seriesrumahsakit: [],
            chartOptionsrumahsakit: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            seriesrumahsakitpengelola: [],
            chartOptionsrumahsakitpengelola: {
                chart: {
                    type: 'donut',
                },

                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            katakunci: '',
        };
    },
    computed: {
        filteredKataDataBandara() {
            const filteredWorkers = this.carikatabandara === ""
                ? this.databandara
                : this.databandara.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatabandara.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataBandara() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataBandara === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataBandara.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataHotel() {
            const filteredWorkers = this.carikatahotel === ""
                ? this.datahotel
                : this.datahotel.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatahotel.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataHotel() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataHotel === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataHotel.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataPariwisata() {
            const filteredWorkers = this.carikatapariwisata === ""
                ? this.datapariwisata
                : this.datapariwisata.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatapariwisata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataPariwisata() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataPariwisata === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataPariwisata.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataPelabuhan() {
            const filteredWorkers = this.carikatapelabuhan === ""
                ? this.datapelabuhan
                : this.datapelabuhan.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatapelabuhan.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataPelabuhan() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataPelabuhan === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataPelabuhan.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataPendidikan() {
            const filteredWorkers = this.carikatapendidikan === ""
                ? this.datapendidikan
                : this.datapendidikan.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatapendidikan.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataPendidikan() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataPendidikan === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataPendidikan.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
        filteredKataDataRumahsakit() {
            const filteredWorkers = this.carikatarumahsakit === ""
                ? this.datarumahsakit
                : this.datarumahsakit.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikatarumahsakit.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataDataRumahsakit() {
            var jumlah = 0;
            try {
                if (this.filteredKataDataRumahsakit === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataDataRumahsakit.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async ambildata() {
            this.halamanloading = true;
            if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                this.katakunci = 'Masukkan Kata Kunci Pencarian';
            }
            else {
                this.katakunci = 'Enter Search Keywords';
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetDataBandara?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriesbandara.push(param[i].jumlah);
                            this.chartOptionsbandara.labels.push(param[i].nama);
                        }
                        this.databandara = [];
                        this.databandara = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriesbandara = [];
                this.chartOptionsbandara = {
                    labels: []
                };
                this.databandara = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("parastapainnovation-GetDataHotel?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.serieshotel.push(param[i].jumlah);
                            this.chartOptionshotel.labels.push(param[i].nama);
                        }
                        this.datahotel = [];
                        this.datahotel = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.serieshotel = [];
                this.chartOptionshotel = {
                    labels: []
                };
                this.datahotel = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("parastapainnovation-GetDataPariwisata?random=" + random).then(
                Response => {
                    this.chartOptionspariwisata = {
                        labels: []
                    };
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriespariwisata.push(param[i].jumlah);
                            this.chartOptionspariwisata.labels.push(param[i].nama);
                        }

                        var parampengelola = Response.data.content.resumepengelola;
                        for (let i = 0; i < parampengelola.length; i++) {
                            this.seriespariwisatapengelola.push(parampengelola[i].jumlah);
                            this.chartOptionspariwisatapengelola.labels.push(parampengelola[i].nama);
                        }
                        this.datapariwisata = [];
                        this.datapariwisata = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriespariwisata = [];
                this.chartOptionspariwisata = {
                    labels: []
                };
                this.datapariwisata = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("parastapainnovation-GetDataPelabuhan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriespelabuhan.push(param[i].jumlah);
                            this.chartOptionspelabuhan.labels.push(param[i].nama);
                        }
                        this.datapelabuhan = [];
                        this.datapelabuhan = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriespelabuhan = [];
                this.chartOptionspelabuhan = {
                    labels: []
                };
                this.datapelabuhan = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            this.seriespendidikan = [];
            this.chartOptionspendidikan = {
                labels: []
            };
            await mainAPI.get("parastapainnovation-GetDataPendidikan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriespendidikan.push(param[i].jumlah);
                            this.chartOptionspendidikan.labels.push(param[i].nama);
                        }
                        this.datapendidikan = [];
                        this.datapendidikan = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriespendidikan = [];
                this.chartOptionspendidikan = {
                    labels: []
                };
                this.datapendidikan = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("parastapainnovation-GetDataRumahsakit?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriesrumahsakit.push(param[i].jumlah);
                            this.chartOptionsrumahsakit.labels.push(param[i].nama);
                        }

                        var parampengelola = Response.data.content.resumepengelola;
                        for (let i = 0; i < parampengelola.length; i++) {
                            this.seriesrumahsakitpengelola.push(parampengelola[i].jumlah);
                            this.chartOptionsrumahsakitpengelola.labels.push(parampengelola[i].nama);
                        }
                        this.datarumahsakit = [];
                        this.datarumahsakit = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriesrumahsakit = [];
                this.chartOptionsrumahsakit = {
                    labels: []
                };
                this.datarumahsakit = [];
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            this.seriespendidikan = [];
            this.chartOptionspendidikan = {
                labels: []
            };
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation-GetDataPendidikan?random=" + random + "&halaman=" + halamannya).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        var param = Response.data.content.resume;
                        for (let i = 0; i < param.length; i++) {
                            this.seriespendidikan.push(param[i].jumlah);
                            this.chartOptionspendidikan.labels.push(param[i].nama);
                        }
                        this.datapendidikan = [];
                        this.datapendidikan = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.seriespendidikan = [];
                this.chartOptionspendidikan = {
                    labels: []
                };
                this.datapendidikan = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>