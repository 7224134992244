<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        style="opacity: 0.7;">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <div class="container-fluid testimonial bg-breadcrumb py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center py-2">
            &nbsp;
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="5000">
            <Slide v-for="slide in slides" :key="slide.id">
                <div class="carousel__item"
                    :style="'background:linear-gradient(0deg, rgba(60, 60, 60, 0.5), rgba(60, 60, 60, 0.5)), url(' + slide.url + ');background-position: center;background-repeat: no-repeat;background-size: cover;background-color: rgba(223, 12, 12, 0);'">
                    <h1 class="col-sm-12 carousel__h1" style="font-style: normal;">{{ slide.title }}</h1>
                    <p class="col-sm-12 text-justify"><span v-html="slide.content"></span></p>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
    <!-- Header End -->
    <div class="container-fluid py-3">
        <div class="row g-0 align-items-center">
            <div class="row justify-content-center" data-wow-delay="0.1s">
                <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                    <input type="text" v-model="carikata" class="form-control form-control-sm" :placeholder="katakunci">
                </div>
                <div class="col-sm-12 float-left pb-2 px-3">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Data:</span>
                        <span v-else>
                            Amount of data:</span>
                     {{ G_numFormat(JumlahfilteredKataData) }}
                    <span style="float: right;">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman ke:</span>
                        <span v-else>
                            Page to</span>
                         <select v-model="halaman" @change="onChangeHalaman($event)">
                            <option v-for="index in jumlahhalaman" :key="index">
                                {{ index }}</option>
                        </select> <span v-if="(bahasa === null) || (bahasa == 'ina')">dari</span>
                        <span v-else>
                            from</span> {{ jumlahhalaman }}
                    </span>
                </div>
            </div>
            <div class="row g-4 justify-content-center" data-wow-delay="0.1s">
                <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s"
                    style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;"
                    v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                    <div class="service-item rounded">
                        <div class="service-img rounded-top">
                            <img :src="folder_foto + datalist.filenya_peluang" class="img-fluid rounded-top w-100"
                                alt="">
                        </div>
                        <div class="service-content rounded-bottom bg-light p-4">
                            <div class="service-content-inner">
                                <h5 class="mb"><span v-if="(bahasa === null) || (bahasa == 'ina')">{{
                                    datalist.nama
                                        }}</span><span v-else>{{ datalist.nama_eng }}</span></h5>
                                <h6 class="mb"><small v-if="(bahasa === null) || (bahasa == 'ina')">{{
                                    datalist.namasektor
                                        }}</small><small v-else>{{ datalist.namasektor_eng }}</small></h6>
                                <p class="mb-4"><span v-if="(bahasa === null) || (bahasa == 'ina')">{{
                                    datalist.namakecamatan }}, {{ datalist.namakelurahan }}</span><span v-else>{{
                                        datalist.namakecamatan }}, {{ datalist.namakelurahan }}</span></p>
                                <a href="javascript:void(0)"
                                    class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                                    @click="bukapotensi(datalist.id)"><small
                                        v-if="(bahasa === null) || (bahasa == 'ina')">Lihat detail
                                    </small><small v-else>Read more</small></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 wow fadeInUp pmisputih" data-wow-delay="0.1s">&nbsp;</div>
            <div class="col-sm-12 wow fadeInUp pmisputih" data-wow-delay="0.1s">
                <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()" style="display: none;"><i
                        class="fas fa-minus text-white"></i></button>
                <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;" frameborder="0"
                    id="maps" scrolling="no" @load="loadpeta()"></iframe>
            </div>
            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4 wow fadeInLeft pmisputih"
                data-wow-delay="0.1s">
                <div class="bg-primary d-flex align-items-center justify-content-center mb-3"
                    style="width: 90px; height: 90px; border-radius: 50px;">
                    <h3 class="text-white">{{ G_numFormat(jumlahproyek) }}</h3>
                </div>
                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Proyek dengan</h4>
                <h4 v-else class="text-dark">Project with</h4>
                <p class="mb-0 text-dark">{{ G_numFormat(totalinvestasi) }} M
                    <small v-if="(bahasa === null) || (bahasa == 'ina')">total investasi</small>
                    <small v-else>total investment</small>
                </p>
            </div>
            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4 wow fadeInLeft pmisputih">
                <div class="bg-warning d-flex align-items-center justify-content-center mb-3"
                    style="width: 90px; height: 90px; border-radius: 50px;">
                    <h3 class="text-white">{{ G_numFormat(jumlahsektor) }}</h3>
                </div>
                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Sektor di</h4>
                <h4 v-else class="text-dark">Sector in</h4>
                <p class="mb-0 text-dark"><span class="info-box-number">
                        {{ G_numFormat(jumlahkecamatan) }}
                        <small v-if="(bahasa === null) || (bahasa == 'ina')">Kecamatan</small>
                        <small v-else>Subdistrict</small>
                    </span></p>
            </div>
            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4 wow fadeInLeft pmisputih">
                <div class="bg-primary d-flex align-items-center justify-content-center mb-3"
                    style="width: 90px; height: 90px; border-radius: 50px;">
                    <h3 class="text-white">{{ G_numFormat(jumlahsektor) }}</h3>
                </div>
                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Sektor di</h4>
                <h4 v-else class="text-dark">Sector in</h4>
                <p class="mb-0 text-dark"><span class="info-box-number">
                        {{ G_numFormat(jumlahkelurahan) }}
                        <small v-if="(bahasa === null) || (bahasa == 'ina')">Kelurahan</small>
                        <small v-else>Village</small>
                    </span></p>
            </div>
            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4 wow fadeInLeft pmisputih">
                <div class="bg-warning d-flex align-items-center justify-content-center mb-3"
                    style="width: 90px; height: 90px; border-radius: 50px;">
                    <h3 class="text-white">{{ G_numFormat(jumlahpengunjung) }}</h3>
                </div>
                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Total</h4>
                <h4 v-else class="text-dark">Total</h4>
                <p class="mb-0 text-dark"><span class="info-box-number">
                        <small v-if="(bahasa === null) || (bahasa == 'ina')">Pengunjung</small>
                        <small v-else>Visitor</small>
                    </span></p>
            </div>
            
        </div>
    </div>
    <!-- Header End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            bahasa: localStorage.getItem("bahasasistem"),
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: '',
            slides: [],
            jumlahproyek: 0,
            totalinvestasi: 0,
            jumlahsektor: 0,
            jumlahkecamatan: 0,
            jumlahkelurahan: 0,
            jumlahpengunjung: 0,
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            datamaster: [],
            carikata: '',
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainvestasi-Gambar?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            datapesan: '',
            katakunci:'',
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        bukapotensi(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailPotensi/" + bytes);
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation-GetDetailPeluangInvestasi?random=" + random + "&halaman=" + halamannya).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                this.datamaster = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            if ((this.bahasa === null) || (this.bahasa == 'ina')){
                this.katakunci='Masukkan Kata Kunci Pencarian';
            }
            else {
                this.katakunci='Enter Search Keywords';
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("datakitasektor-GetSektorSlider?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.slides = [];
                        this.datamaster = Response.data.content.data;
                        if ((this.bahasa == null) || (this.bahasa == 'ina')) {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama,
                                    content: atob(item.konten),
                                    url: process.env.VUE_APP_URL_API + 'datakita_sektor-Gambar?f=' + item.filenya_sektor,
                                });
                            });
                        }
                        else {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama_eng,
                                    content: atob(item.konten_eng),
                                    url: process.env.VUE_APP_URL_API + 'datakita_sektor-Gambar?f=' + item.filenya_sektor,
                                });
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("parastapainnovation-GetPeluangInvestasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlahproyek = Response.data.content.data[0].jumlahproyek;
                        this.totalinvestasi = Response.data.content.data[0].totalinvestasi / 1000000000;
                        this.jumlahsektor = Response.data.content.data[0].countsektor;
                        this.jumlahkecamatan = Response.data.content.data[0].countkecamatan;
                        this.jumlahkelurahan = Response.data.content.data[0].countkelurahan;
                        this.jumlahpengunjung = Response.data.content.data[0].jumlahpengunjung;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            });
            await mainAPI.get("parastapainnovation-GetDetailPeluangInvestasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            });
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style>
.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid #0092cf;
    border-radius: 40px;
    background-color: #0092cf;
    color: aliceblue;
    height: 50px;
    width: 50px;
}
</style>