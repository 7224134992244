<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Lampiran</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/PeluangInvestasi">Peluang Investasi</a></li>
                                <li class="breadcrumb-item active">Pengaturan Peluang Investasi</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Peluang Investasi</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Sektor<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="sektor" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedsektor" @update:modelValue="onsektorChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>KBLI<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kbli" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkbli" @update:modelValue="onkbliChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6 py-3">
                                            <label>Kecamatan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkecamatan"
                                                @update:modelValue="onkecamatanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6 py-3">
                                            <label>Kelurahan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kelurahan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkelurahan"
                                                @update:modelValue="onkelurahanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Peluang Investasi <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Peluang Investasi" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Peluang Investasi English<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama_eng"
                                                    name="nama_eng" placeholder="Nama Peluang Investasi English"
                                                    v-model="nama_eng">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tahun <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="tahun"
                                                    name="tahun" placeholder="Tahun" v-model="tahun">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nilai Investasi (Rp.) <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nilai"
                                                    name="nilai" placeholder="Nilai Investasi (Rp.)" v-model="nilai"
                                                    @keyup="formatRupiah('nilai')"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>IRR (%) <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="irr"
                                                    name="irr" placeholder="IRR (%)" v-model="irr">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>NPV (Rp.) <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="npv"
                                                    name="npv" placeholder="NPV (Rp.)" v-model="npv"
                                                    @keyup="formatRupiah('npv')"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Payback Period (Tahun) <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="payback"
                                                    name="payback" placeholder="Payback Period (Tahun)"
                                                    v-model="payback">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kontak <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="kontak"
                                                    name="kontak" placeholder="Kontak" v-model="kontak">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="alamat"
                                                    name="alamat" placeholder="Alamat" v-model="alamat">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nomor Telepon </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="telp"
                                                    name="telp" placeholder="Nomor Telepon" v-model="telp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat Email </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="email"
                                                    name="email" placeholder="Alamat Email" v-model="email">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat Website </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="web"
                                                    name="web" placeholder="Alamat Website" v-model="web">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Longitude <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="longitude"
                                                    name="longitude" placeholder="Longitude" v-model="longitude">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Latitude <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="latitude"
                                                    name="latitude" placeholder="Latitude" v-model="latitude">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Isi Deskripsi <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="konten"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>Isi Deskripsi English <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="konten_eng"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12">
                                            <label>Gambar Peluang Investasi<small class="text-info"> (*jpg - wajib
                                                    diisi)</small>
                                            </label>
                                            <label v-if="filenya_peluang != ''" style="float: right;"> <a
                                                    href="javascript:void(0)"
                                                    @click="lihatlampiran(filenya_peluang, 'parastapainvestasi-Gambar')"><i
                                                        class="fas fa-file-image"></i> Lihat
                                                    Gambar</a></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_peluang" name="fileToUploadfilenya_peluang"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_peluang" name="filenya_peluang"
                                                    placeholder="File Beranda">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_peluang">
                                                            <span id="tulisanpersenfilenya_peluang">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_peluang"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_peluang', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_peluang')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            dleveluser: JSON.parse(localStorage.getItem("lsistem")),
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kecamatanuser: JSON.parse(localStorage.getItem("bdlsistem")),
            kelurahanuser: JSON.parse(localStorage.getItem("sbdsistem")),
            namakecamatanuser: JSON.parse(localStorage.getItem("nmbdlsistem")),
            namakelurahanuser: JSON.parse(localStorage.getItem("nmsbdsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            nama: '',
            nama_eng: '',
            filenya_peluang: '',
            konten: '',
            konten_eng: '',
            sektor: [],
            datasektor: [],
            selectedsektor: 0,
            defaultSelectedsektor: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },

            kbli: [],
            datakbli: [],
            selectedkbli: 0,
            defaultSelectedkbli: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            tahun: '',
            nilai: '',
            irr: '',
            npv: '',
            payback: '',

            longitude: '',
            latitude: '',
            kontak: '',
            alamat: '',
            telp: '',
            email: '',
            web: '',

            kecamatan: [],
            datakecamatan: [],
            selectedkecamatan: 0,
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },

            kelurahan: [],
            datakelurahan: [],
            selectedkelurahan: 0,
            defaultSelectedkelurahan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },

            validasi: false,
        }
    },
    methods: {
        lihatlampiran(filenya, alamat) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + alamat + '?f=' + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        formatRupiah(kode) {
            //alert(document.getElementById(kode).value);
            var prefix = '';
            var angka = document.getElementById(kode).value;
            var number_string = angka.replace(/[^,\d]/g, '').toString();
            var split = number_string.split(',');
            var sisa = split[0].length % 3;
            var rupiah = split[0].substr(0, sisa);
            var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

            if (ribuan) {
                var separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }

            rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
            document.getElementById(kode).value = rupiah;
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onsektorChange(a) {
            try {
                this.selectedsektor = this.sektor[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkbliChange(a) {
            try {
                this.selectedkbli = this.kbli[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
                this.AmbilDataKelurahan();
            }
            catch (err) {
                return;
            }
        },
        onkelurahanChange(a) {
            try {
                this.selectedkelurahan = this.kelurahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async AmbilDataKelurahan() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var kodekecamatan = '';
            if (this.selectedkecamatan != 0) {
                kodekecamatan = this.selectedkecamatan;
            }
            var kodekelurahan = "";
            if (this.selectedkelurahan != 0) {
                kodekelurahan = this.selectedkelurahan;
            }
            await mainAPIData.get("datalokasi-GetKelurahan?random=" + random + "&kodekecamatan=" + kodekecamatan + "&kodekelurahan=" + kodekelurahan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakelurahan = [];
                        this.kelurahan = [];
                        this.datakelurahan = Response.data.content.data;
                        this.datakelurahan.forEach((item) => {
                            this.kelurahan.push({
                                label: item.Kelurahan,
                                code: this.datakelurahan.indexOf(item) + 1,
                                tag: item.KodeKelurahan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        batalproses() {
            window.location.replace("/PeluangInvestasi");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_peluang = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataReferensi() {
            this.halamanloading = true;
            var mainAPIDataReferensi = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReferensi.get("datakita_sektor-GetSektor?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasektor = [];
                        this.sektor = [];
                        this.datasektor = Response.data.content.data;
                        this.datasektor.forEach((item) => {
                            this.sektor.push({
                                label: item.nama,
                                code: this.datasektor.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.sektor.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datasektor = [];
                this.sektor = [];
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReferensi.get("datakita_klbi-GetKLBI?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakbli = [];
                        this.kbli = [];
                        this.datakbli = Response.data.content.data;
                        this.datakbli.forEach((item) => {
                            this.kbli.push({
                                label: '(' + item.kode + ') ' + item.nama,
                                code: this.datakbli.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kbli.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datakbli = [];
                this.kbli = [];
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var kodekecamatan = this.kecamatanuser;
            if (kodekecamatan == '0') {
                kodekecamatan = '';
            }

            var kodekelurahan = this.kelurahanuser;
            if (kodekelurahan == '0') {
                kodekelurahan = '';
            }

            await mainAPIDataReferensi.get("datalokasi-GetKecamatan?random=" + random + "&kodekecamatan=" + kodekecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Kecamatan,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.KodeKecamatan,
                            });
                        });
                        if (kodekecamatan == '') {
                            this.kecamatan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: '',
                            });
                        }
                        else {
                            this.defaultSelectedkecamatan = {
                                code: this.kecamatanuser,
                                label: this.namakecamatanuser,
                                tag: this.kecamatanuser,
                            };
                            this.selectedkecamatan = this.kecamatanuser;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datakecamatan = [];
                this.kecamatan = [];
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReferensi.get("datalokasi-GetKelurahan?random=" + random + "&kodekecamatan=" + kodekecamatan + "&kodekelurahan=" + kodekelurahan).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakelurahan = [];
                        this.kelurahan = [];
                        this.datakelurahan = Response.data.content.data;
                        this.datakelurahan.forEach((item) => {
                            this.kelurahan.push({
                                label: item.Kelurahan,
                                code: this.datakelurahan.indexOf(item) + 1,
                                tag: item.KodeKelurahan,
                            });
                        });
                        if (kodekelurahan == '') {
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: '',
                            });
                        }
                        else {
                            this.defaultSelectedkelurahan = {
                                code: this.kelurahanuser,
                                label: this.namakelurahanuser,
                                tag: this.kelurahanuser,
                            };
                            this.selectedkelurahan = this.kelurahanuser;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datakelurahan = [];
                this.kelurahan = [];
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainvestasi-GetPeluangInvestasiByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.selectedsektor = Response.data.content.data[0].sektor;
                    this.defaultSelectedsektor = {
                        code: Response.data.content.data[0].sektor,
                        label: Response.data.content.data[0].namasektor,
                        tag: Response.data.content.data[0].sektor,
                    };
                    this.selectedkbli = Response.data.content.data[0].id_kbli;
                    this.defaultSelectedkbli = {
                        code: Response.data.content.data[0].id_kbli,
                        label: '(' + Response.data.content.data[0].kodekbli + ') ' + Response.data.content.data[0].namakbli,
                        tag: Response.data.content.data[0].id_kbli,
                    };
                    this.selectedkecamatan = Response.data.content.data[0].kecamatan;
                    this.defaultSelectedkecamatan = {
                        code: Response.data.content.data[0].kecamatan,
                        label: Response.data.content.data[0].namakecamatan,
                        tag: Response.data.content.data[0].kecamatan,
                    };
                    this.selectedkelurahan = Response.data.content.data[0].kelurahan;
                    this.defaultSelectedkelurahan = {
                        code: Response.data.content.data[0].kelurahan,
                        label: Response.data.content.data[0].namakelurahan,
                        tag: Response.data.content.data[0].kelurahan,
                    };
                    this.tahun = Response.data.content.data[0].tahun;
                    this.nilai = Response.data.content.data[0].nilai.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    this.irr = Response.data.content.data[0].irr;
                    this.npv = Response.data.content.data[0].npv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    this.payback = Response.data.content.data[0].payback;
                    this.kontak = Response.data.content.data[0].kontak;
                    this.alamat = Response.data.content.data[0].alamat;
                    this.telp = Response.data.content.data[0].telp;
                    this.email = Response.data.content.data[0].email;
                    this.web = Response.data.content.data[0].web;
                    this.longitude = Response.data.content.data[0].longitude;
                    this.latitude = Response.data.content.data[0].latitude;
                    this.nama = Response.data.content.data[0].nama;
                    this.nama_eng = Response.data.content.data[0].nama_eng;
                    this.konten = atob(Response.data.content.data[0].konten);
                    this.konten_eng = atob(Response.data.content.data[0].konten_eng);
                    this.filenya_peluang = Response.data.content.data[0].filenya_peluang;
                    document.getElementById('filenya_peluang').value = Response.data.content.data[0].filenya_peluang;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if ((this.selectedsektor == '0') || (this.selectedsektor == null) || (this.selectedsektor == undefined) || (this.selectedsektor == '')) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Sektor',
                });
                return false;
            }
            if ((this.selectedkbli == '0') || (this.selectedkbli == null) || (this.selectedkbli == undefined) || (this.selectedkbli == '')) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan KBLI',
                });
                return false;
            }
            if ((this.selectedkecamatan == '0') || (this.selectedkecamatan == null) || (this.selectedkecamatan == undefined) || (this.selectedkecamatan == '')) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Kecamatan',
                });
                return false;
            }
            if ((this.selectedkelurahan == '0') || (this.selectedkelurahan == null) || (this.selectedkelurahan == undefined) || (this.selectedkelurahan == '')) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Kelurahan',
                });
                return false;
            }
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Nama',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (document.getElementById('nama_eng').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Nama',
                });
                document.getElementById('nama_eng').focus();
                return false;
            }
            if (document.getElementById('tahun').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tahun',
                });
                document.getElementById('tahun').focus();
                return false;
            }
            if (document.getElementById('nilai').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Nilai',
                });
                document.getElementById('nilai').focus();
                return false;
            }
            if (document.getElementById('irr').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan IRR',
                });
                document.getElementById('irr').focus();
                return false;
            }
            if (document.getElementById('npv').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan NPV',
                });
                document.getElementById('npv').focus();
                return false;
            }
            if (document.getElementById('payback').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Pay Back',
                });
                document.getElementById('payback').focus();
                return false;
            }
            if (document.getElementById('kontak').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Kontak',
                });
                document.getElementById('kontak').focus();
                return false;
            }
            if (document.getElementById('alamat').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Alamat',
                });
                document.getElementById('alamat').focus();
                return false;
            }
            if (document.getElementById('longitude').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Longitude',
                });
                document.getElementById('longitude').focus();
                return false;
            }
            if (document.getElementById('latitude').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('latitude Latitude').focus();
                return false;
            }
            if (this.konten == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Konten',
                });
                return false;
            }
            if (this.konten_eng == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Konten',
                });
                return false;
            }
            if (document.getElementById('filenya_peluang').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Gambar',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("sektor", this.selectedsektor);
            fd.append("kode_kbli", this.selectedkbli);
            fd.append("tahun", this.tahun);
            fd.append("nilai", this.nilai.replaceAll('.', ''));
            fd.append("irr", this.irr);
            fd.append("npv", this.npv.replaceAll('.', ''));
            fd.append("payback", this.payback);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("longitude", this.longitude);
            fd.append("latitude", this.latitude);
            fd.append("kontak", this.kontak);
            fd.append("alamat", this.alamat);
            fd.append("kontak", this.kontak);
            fd.append("telp", this.telp);
            fd.append("email", this.email);
            fd.append("web", this.web);
            fd.append("filenya_peluang", document.getElementById('filenya_peluang').value);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainvestasi-AddPeluangInvestasi", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/PeluangInvestasi");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("sektor", this.selectedsektor);
            fd.append("kode_kbli", this.selectedkbli);
            fd.append("tahun", this.tahun);
            fd.append("nilai", this.nilai.replaceAll('.', ''));
            fd.append("irr", this.irr);
            fd.append("npv", this.npv.replaceAll('.', ''));
            fd.append("payback", this.payback);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("longitude", this.longitude);
            fd.append("latitude", this.latitude);
            fd.append("kontak", this.kontak);
            fd.append("alamat", this.alamat);
            fd.append("kontak", this.kontak);
            fd.append("telp", this.telp);
            fd.append("email", this.email);
            fd.append("web", this.web);
            fd.append("filenya_peluang", document.getElementById('filenya_peluang').value);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainvestasi-UpdatePeluangInvestasi", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/PeluangInvestasi");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilDataReferensi();
        this.initialize();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>