<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'DataProfil'">
    <DataProfil/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'DetailPotensi'">
    <DetailPotensi/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'PeluangInvestasi'">
    <PeluangInvestasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPeluangInvestasi'">
    <ProsesPeluangInvestasi/>
  </div>
  <div class="content" v-if="route.name == 'LampiranPeluangInvestasi'">
    <LampiranPeluangInvestasi/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'Profil'">
    <Profil/>
  </div>
  <div class="content" v-if="route.name == 'ProsesProfil'">
    <ProsesProfil/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'Sektor'">
    <Sektor/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSektor'">
    <ProsesSektor/>
  </div>
  <div class="content" v-if="route.name == 'KBLI'">
    <KBLI/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKBLI'">
    <ProsesKBLI/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'Infrastruktur'">
    <Infrastruktur/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import DataProfil from './components/views/Publik/DataProfil.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import GIS from './components/views/Publik/GIS.vue';
import DetailPotensi from './components/views/Publik/DetailPotensi.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import Profil from './components/views/Backoffice/Profil/Profil.vue';
import ProsesProfil from './components/views/Backoffice/Profil/ProsesProfil.vue';
import PeluangInvestasi from './components/views/Backoffice/PeluangInvestasi/PeluangInvestasi.vue';
import ProsesPeluangInvestasi from './components/views/Backoffice/PeluangInvestasi/ProsesPeluangInvestasi.vue';
import LampiranPeluangInvestasi from './components/views/Backoffice/PeluangInvestasi/LampiranPeluangInvestasi.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import Sektor from './components/views/Backoffice/Sektor/Sektor.vue';
import ProsesSektor from './components/views/Backoffice/Sektor/ProsesSektor.vue';
import KBLI from './components/views/Backoffice/KBLI/KBLI.vue';
import ProsesKBLI from './components/views/Backoffice/KBLI/ProsesKBLI.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import Infrastruktur from './components/views/Publik/Infrastruktur.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,GIS,Dashboard
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda,PeluangInvestasi,ProsesPeluangInvestasi,LampiranPeluangInvestasi
    ,KategoriData,ProsesKategoriData,Sektor,ProsesSektor,KBLI,ProsesKBLI,LayerPeta,ProsesLayerPeta
    ,User,ProsesUser,DetailPotensi,Profil,ProsesProfil,DataProfil,Regulasi,ProsesRegulasi,DaftarRegulasi,Infrastruktur
  },
  mounted() {
    document.title = "DPMPT BALIKPAPAN";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
