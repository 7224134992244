<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/User">Pengguna</a></li>
                                <li class="breadcrumb-item active">Pengaturan Pengguna</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Pengguna</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Username <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="usernamedata" name="usernamedata" placeholder="Username"
                                                    v-model="usernamedata">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="usernamedatatmp" name="usernamedatatmp" placeholder="Username"
                                                    v-model="usernamedatatmp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Password User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="password" class="form-control form-control-sm"
                                                    id="passworddata" name="passworddata" placeholder="Password User"
                                                    v-model="passworddata">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="namadata"
                                                    name="namadata" placeholder="Nama User" v-model="namadata">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Level User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="leveluser" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedleveluser"
                                                @update:modelValue="onleveluserChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kecamatan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkecamatan"
                                                @update:modelValue="onkecamatanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kelurahan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="kelurahan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkelurahan"
                                                @update:modelValue="onkelurahanChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            iduser: JSON.parse(localStorage.getItem("usistem")),
            dleveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            levelkecamatan: JSON.parse(localStorage.getItem("bdlsistem")),
            levelkelurahan: JSON.parse(localStorage.getItem("sbdsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            usernamedata: '',
            usernamedatatmp: '',
            namadata: '',
            passworddata: '',
            leveluser: [],
            dataleveluser: [],
            defaultSelectedleveluser: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedleveluser: 0,
            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkecamatan: 0,
            kelurahan: [],
            datakelurahan: [],
            defaultSelectedkelurahan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkelurahan: 0,
            validasi: false,
        }
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onleveluserChange(a) {
            try {
                this.selectedleveluser = this.leveluser[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
                this.selectedkelurahan = 0;
                this.AmbilDataKelurahan();
            }
            catch (err) {
                return;
            }
        },
        onkelurahanChange(a) {
            try {
                this.selectedkelurahan = this.kelurahan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/User");
        },
        async AmbilData() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("datalevel-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var random = Math.random();
            var kodekecamatan = "";
            if (this.levelkecamatan != 0) {
                kodekecamatan = this.levelkecamatan;
            }
            var kodekelurahan = "";
            if (this.levelkelurahan != 0) {
                kodekelurahan = this.levelkelurahan;
            }
            await mainAPIData.get("datalokasi-GetKecamatan?random=" + random + "&kodekecamatan=" + kodekecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Kecamatan,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.KodeKecamatan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkecamatan = [];
                            this.defaultSelectedkecamatan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kecamatan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkecamatan = [];
                            this.defaultSelectedkecamatan.unshift({
                                label: 'Semua Kecamatan',
                                code: 0,
                                tag: 0,
                            });
                            this.kecamatan.unshift({
                                label: 'Semua Kecamatan',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var random = Math.random();
            await mainAPIData.get("datalokasi-GetKelurahan?random=" + random + "&kodekecamatan=" + kodekecamatan + "&kodekelurahan=" + kodekelurahan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakelurahan = [];
                        this.kelurahan = [];
                        this.datakelurahan = Response.data.content.data;
                        this.datakelurahan.forEach((item) => {
                            this.kelurahan.push({
                                label: item.Kelurahan,
                                code: this.datakelurahan.indexOf(item) + 1,
                                tag: item.KodeKelurahan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataKelurahan() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var kodekecamatan = '';
            if (this.selectedkecamatan != 0) {
                kodekecamatan = this.selectedkecamatan;
            }
            if (this.levelkecamatan != 0) {
                kodekecamatan = this.levelkecamatan;
            }
            var kodekelurahan = "";
            if (this.levelkelurahan != 0) {
                kodekelurahan = this.levelkelurahan;
            }
            await mainAPIData.get("datalokasi-GetKelurahan?random=" + random + "&kodekecamatan=" + kodekecamatan + "&kodekelurahan=" + kodekelurahan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakelurahan = [];
                        this.kelurahan = [];
                        this.datakelurahan = Response.data.content.data;
                        this.datakelurahan.forEach((item) => {
                            this.kelurahan.push({
                                label: item.Kelurahan,
                                code: this.datakelurahan.indexOf(item) + 1,
                                tag: item.KodeKelurahan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("datalevel-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var random = Math.random();
            var kodekecamatan = '';
            if (this.selectedkecamatan != 0) {
                kodekecamatan = this.selectedkecamatan;
            }
            if (this.levelkecamatan != 0) {
                kodekecamatan = this.levelkecamatan;
            }
            var kodekelurahan = "";
            if (this.levelkelurahan != 0) {
                kodekelurahan = this.levelkelurahan;
            }
            await mainAPIData.get("datalokasi-GetKecamatan?random=" + random + "&kodekecamatan=" + kodekecamatan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Kecamatan,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.KodeKecamatan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkecamatan = [];
                            this.defaultSelectedkecamatan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kecamatan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkecamatan = [];
                            this.defaultSelectedkecamatan.unshift({
                                label: 'Semua Kecamatan',
                                code: 0,
                                tag: 0,
                            });
                            this.kecamatan.unshift({
                                label: 'Semua Kecamatan',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            var random = Math.random();
            await mainAPIData.get("datalokasi-GetKelurahan?random=" + random + "&kodekecamatan=" + kodekecamatan + "&kodekelurahan=" + kodekelurahan).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakelurahan = [];
                        this.kelurahan = [];
                        this.datakelurahan = Response.data.content.data;
                        this.datakelurahan.forEach((item) => {
                            this.kelurahan.push({
                                label: item.Kelurahan,
                                code: this.datakelurahan.indexOf(item) + 1,
                                tag: item.KodeKelurahan,
                            });
                        });

                        if (this.dleveluser > 6) {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Pilih Salah Satu',
                                code: 0,
                                tag: 0,
                            });
                        }
                        else {
                            this.defaultSelectedkelurahan = [];
                            this.defaultSelectedkelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                            this.kelurahan.unshift({
                                label: 'Semua Kelurahan',
                                code: 0,
                                tag: 0,
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("datakitauserNonMD5-GetUser?kduser=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.usernamedata = Response.data.content.data[0].username;
                    this.usernamedatatmp = Response.data.content.data[0].username;
                    this.namadata = Response.data.content.data[0].nama;
                    this.passworddata = Response.data.content.data[0].password;

                    this.defaultSelectedleveluser = {
                        code: Response.data.content.data[0].level,
                        label: Response.data.content.data[0].namaleveluser,
                        tag: Response.data.content.data[0].level,
                    };
                    this.selectedleveluser = Response.data.content.data[0].level;
                    this.defaultSelectedkecamatan = {
                        code: Response.data.content.data[0].bidang,
                        label: Response.data.content.data[0].akseskecamatan,
                        tag: Response.data.content.data[0].bidang,
                    };
                    this.selectedkecamatan = Response.data.content.data[0].bidang;

                    this.defaultSelectedkelurahan = {
                        code: Response.data.content.data[0].subbidang,
                        label: Response.data.content.data[0].akseskelurahan,
                        tag: Response.data.content.data[0].subbidang,
                    };
                    this.selectedkelurahan = Response.data.content.data[0].bidang;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('usernamedata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('usernamedata').focus();
                return false;
            }
            if (document.getElementById('passworddata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('passworddata').focus();
                return false;
            }
            if (document.getElementById('namadata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('namadata').focus();
                return false;
            }
            if (this.selectedleveluser == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                return false;
            }
            if (this.dleveluser > 6) {
                if (this.selectedkecamatan == 0) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    return false;
                }
                if (this.selectedkelurahan == 0) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    return false;
                }
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakitauser-AddUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("usernametmp", this.usernamedatatmp);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kecamatan", this.selectedkecamatan);
            fd.append("kelurahan", this.selectedkelurahan);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakitauser-EditUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
        else {
            this.AmbilData();
        }
    }
}
</script>
<style></style>