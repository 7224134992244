<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        style="opacity: 0.7;">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="5000">
            <Slide v-for="slide in slides" :key="slide.id">
                <div class="carousel__item"
                    :style="'background:linear-gradient(0deg, rgba(60, 60, 60, 0.5), rgba(60, 60, 60, 0.5)), url(' + slide.url + ');background-position: center;background-repeat: no-repeat;background-size: cover;background-color: rgba(223, 12, 12, 0);'">
                    <h1 class="col-sm-12 carousel__h1" style="font-style: normal;">{{ slide.title }}</h1>

                </div>
            </Slide>
        </Carousel>
    </div>
    <div class="container-fluid blog py-4">
        <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li class="breadcrumb-item">
                <a href="/GIS">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">kembali ke Peluang Investasi</span>
                    <span v-else>back to Invesment Opportunities</span>
                </a>
            </li>
        </ol>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-0 px-0">
        <div class="container py-2 px-0">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="sub-style mb-4">
                    <h4 class="sub-title text-white px-0 mb-0">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Resume</span>
                        <span v-else>Resumes</span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 px-2 wow fadeInLeft pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="far fa-edit"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">KBLI</span>
                            <span class="info-box-text" v-else> KBLI</span>
                            <span class="info-box-number">
                                {{ kode_kbli }} - {{ kbli }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInLeft pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-filter"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">Tahun</span>
                            <span class="info-box-text" v-else> Years</span>
                            <span class="info-box-number">
                                {{ tahun }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInRight pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fab fa-firstdraft"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">Nilai
                                Investasi</span>
                            <span class="info-box-text" v-else> Investment Value</span>
                            <span class="info-box-number">
                                {{ G_numFormat(nilai) }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInRight pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fab fa-flickr"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">IRR</span>
                            <span class="info-box-text" v-else> IRR</span>
                            <span class="info-box-number">
                                {{ G_numFormatKoma(IRR) }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInLeft pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fab fa-flipboard"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">NPV</span>
                            <span class="info-box-text" v-else> NPV</span>
                            <span class="info-box-number">
                                {{ G_numFormat(NPV) }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInLeft pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-folder-plus"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">Payback
                                Period</span>
                            <span class="info-box-text" v-else> Payback Period</span>
                            <span class="info-box-number">
                                {{ Payback }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInRight pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-map-marker"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">Longitude</span>
                            <span class="info-box-text" v-else> Longitude</span>
                            <span class="info-box-number">
                                {{ Longitude }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInRight pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-map-marker-alt"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text" v-if="(bahasa === null) || (bahasa == 'ina')">Latitude</span>
                            <span class="info-box-text" v-else> Latitude</span>
                            <span class="info-box-number">
                                {{ Latitude }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
                <div class="col-sm-3 px-2 wow fadeInRight pmisputih" data-wow-delay="0.1s">
                    <div class="info-box bg-info">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-map-signs"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-number">
                                {{ namakelurahan }}, {{ namakecamatan }}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container py-2 px-0">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="sub-style mb-4">
                    <h4 class="sub-title text-white px-0 mb-0">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Deskripsi</span>
                        <span v-else>Description</span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-7 wow fadeInRight" data-wow-delay="0.3s">
                    <p class="mb-0 text-black text-justify">{{ removeTags(judul) }}</p>
                </div>
                <div class="col-sm-5 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="rounded h-100">
                        <iframe class="rounded w-100" style="height: 500px;"
                            :src="'https://maps.google.com/maps?q=' + Latitude + ',' + Longitude + '&hl=es&z=14&amp;output=embed'"
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="container py-2 px-0">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="sub-style">
                    <h4 class="sub-title px-3 mb-0">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Dokumentasi</span>
                        <span v-else>Documentation</span>
                    </h4>
                </div>
                <div class="row g-4 justify-content-center">
                    <div>&nbsp;</div>
                    <div class="col-md-6 col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay="0.1s" v-for="(datalist, urutlist) in lampiran" :key="urutlist">
                        <div class="team-item rounded">
                            <div class="team-img rounded-top h-100">
                                <img :src="folder_fotodetail+datalist.filenya_lampiran"
                                    class="img-fluid rounded-top w-100" alt="">
                            </div>
                            <div class="team-content text-center border border-primary border-top-0 rounded-bottom p-4">
                                <p class="mb-0" v-if="(bahasa === null) || (bahasa == 'ina')">{{ datalist.nama }}</p>
                                <p class="mb-0" v-else>{{ datalist.nama_eng }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container py-2 px-0">
            <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="sub-style">
                    <h4 class="sub-title px-3 mb-0">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Kontak</span>
                        <span v-else>Contact</span>
                    </h4>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                        <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
                        <div class="row wow fadeInRight" data-wow-delay="0.3s">
                            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4">
                                <div class="bg-white d-flex align-items-center justify-content-center mb-3"
                                    style="width: 90px; height: 90px; border-radius: 50px;"><i
                                        class="fa fa-columns fa-2x text-primary"></i></div>
                                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Kontak</h4>
                                <h4 v-else class="text-dark">Contact</h4>
                                <p class="mb-0 text-dark">{{ kontak }}</p>
                            </div>
                            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4">
                                <div class="bg-white d-flex align-items-center justify-content-center mb-3"
                                    style="width: 90px; height: 90px; border-radius: 50px;"><i
                                        class="fa fa-map-marker-alt fa-2x text-primary"></i></div>
                                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Alamat</h4>
                                <h4 v-else class="text-dark">Addresses</h4>
                                <p class="mb-0 text-dark">{{ alamat }}</p>
                            </div>
                            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4">
                                <div class="bg-white d-flex align-items-center justify-content-center mb-3"
                                    style="width: 90px; height: 90px; border-radius: 50px;"><i
                                        class="fa fa-phone fa-2x text-primary"></i></div>
                                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Telepon</h4>
                                <h4 v-else class="text-dark">Telephone</h4>
                                <p class="mb-0 text-dark">{{ telp }}</p>
                            </div>
                            <div class="col-sm-3 d-flex flex-column align-items-center text-center mb-4">
                                <div class="bg-white d-flex align-items-center justify-content-center mb-3"
                                    style="width: 90px; height: 90px; border-radius: 50px;"><i
                                        class="fas fa-window-maximize fa-2x text-primary"></i></div>
                                <h4 v-if="(bahasa === null) || (bahasa == 'ina')" class="text-dark">Email</h4>
                                <h4 v-else class="text-dark">Email</h4>
                                <p class="mb-0 text-dark">{{ email }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact End -->
    <div class="container-fluid blog py-4">
        <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
            <li class="breadcrumb-item">
                <a href="/GIS">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">kembali ke Peluang Investasi</span>
                    <span v-else>back to Invesment Opportunities</span>
                </a>
            </li>
        </ol>
    </div>
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            linknya: process.env.VUE_APP_URL_API,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            datamaster: [],
            carikata: '',
            datapesan: '',
            judul: '',
            gambar: '',
            kbli: '',
            kode_kbli: '',
            tahun: '',
            nilai: '',
            IRR: '',
            NPV: '',
            Payback: '',
            Longitude: '',
            Latitude: '',
            telp: '',
            kontak: '',
            alamat: '',
            email: '',
            namakecamatan: '',
            namakelurahan: '',
            lampiran: [],
            slides: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainvestasi-Gambar?f=',
            folder_fotodetail: process.env.VUE_APP_URL_API + 'parastapainvestasilampiran-Gambar?f=',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        };
    },
    methods: {
        loadkontak() {
            this.halamanloading = true;
            const message = this.linknya;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = 'detail.html';
            iframe.contentWindow.postMessage(message, "*");

            this.halamanloading = false;
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetDetailPeluangInvestasiByID?random=" + random + "&id=" + this.secretencData).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.slides = [];
                        this.datamaster = Response.data.content.data;
                        this.datamaster.forEach((item) => {
                            this.slides.push({
                                id: item.id,
                                title: item.nama,
                                content: item.nama,
                                url: this.folder_foto + item.filenya_peluang,
                            });
                        });
                        if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                            this.judul = Response.data.content.data[0].konten;
                            this.kbli = Response.data.content.data[0].namakbli;
                        }
                        else {
                            this.judul = Response.data.content.data[0].konten_eng;
                            this.kbli = Response.data.content.data[0].namakbli;
                        }
                        this.gambar = Response.data.content.data[0].filenya_peluang;
                        this.tahun = Response.data.content.data[0].tahun;
                        this.nilai = Response.data.content.data[0].nilai;
                        this.IRR = Response.data.content.data[0].irr;
                        this.NPV = Response.data.content.data[0].npv;
                        this.Payback = Response.data.content.data[0].payback;
                        this.Longitude = Response.data.content.data[0].longitude;
                        this.Latitude = Response.data.content.data[0].latitude;
                        this.kontak = Response.data.content.data[0].kontak;
                        this.alamat = Response.data.content.data[0].alamat;
                        this.telp = Response.data.content.data[0].telp;
                        this.email = Response.data.content.data[0].email;
                        this.kode_kbli = Response.data.content.data[0].kode_kbli;
                        this.namakecamatan = Response.data.content.data[0].namakecamatan;
                        this.namakelurahan = Response.data.content.data[0].namakelurahan;
                        this.lampiran = Response.data.content.data[0].lampiran;
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                this.datamaster = [];
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>